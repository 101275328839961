import { z } from "zod";

export const directorySetupRequestSchema = z.object({
  directoryID: z.string(),

  status: z.enum(["created", "processing", "complete", "error"]),

  fileURL: z.string().optional(),

  mainCategory: z.string().optional(),
  secondaryCategory: z.string().optional(),

  createdAt: z.number(),
  completedAt: z.number().optional(),
});

export type DirectorySetupRequestType = z.infer<
  typeof directorySetupRequestSchema
>;
