import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Logger, { type LogAdapter } from "../common/log";
import type { PublitEnvironment, PublitService } from "../common/types";

export const createLogger = (service: PublitService): Logger => {
  const adapter: LogAdapter = {
    appendLog: async (line) => {
      const result = await firebase.firestore().collection("logs").add(line);
      return result.id;
    },
  };

  return new Logger(
    service,
    process.env.PUBLIT_ENV as PublitEnvironment,
    adapter,
  );
};
