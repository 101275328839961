import { z } from "zod";

import type { ObjectWatchableModel } from "./shared";

export const objectUpdateSchema = z.object({
  updatedAt: z.number(),
  updatedBy: z.string(),
});

export type ObjectUpdateType = z.infer<typeof objectUpdateSchema>;

export const objectUpdatePath = (
  userID: string,
  type: ObjectWatchableModel,
  id?: string,
) => {
  if (!id) {
    return `object-updates/${userID}/${type}`;
  }

  return `object-updates/${userID}/${type}/${id}`;
};
