import { z } from "zod";

import { directoryValidationInvalidResult } from "./directory/schema";

export const publishResultSchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("site"), success: z.boolean() }),
  z.object({
    type: z.literal("directory"),
    success: z.boolean(),
    validationError: directoryValidationInvalidResult.optional(),
  }),
]);

export type PublishResultType = z.infer<typeof publishResultSchema>;
