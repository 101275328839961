import { z } from "zod";
import {
  baseObjectFields,
  cropHistorySchema,
  imageCropSchema,
  richTextSchema,
} from "../shared";
// SITE

// COMPLETED_STAGE
export const completedStageSchema = z.enum([
  "signup",
  "purchase",
  "domain-purchased",
  "domain-configured",
]);
export type CompletedStageType = z.infer<typeof completedStageSchema>;

// SiteThemeName
export const siteThemeNameSchema = z.enum([
  "classic",
  "minimal",
  "modern",
  "visual",
]);
export type SiteThemeNameType = z.infer<typeof siteThemeNameSchema>;

export const siteDeploymentInfoSchema = z.object({
  publishedAt: z.number(),
  currentSnapshotID: z.string(),
});
export type SiteDeploymentInfoType = z.infer<typeof siteDeploymentInfoSchema>;

export const siteThemeSchema = z.object({
  color: z.string(),
  secondaryColor: z.string().nullish(),
  name: siteThemeNameSchema.default("classic"),
});
export type SiteThemeType = z.infer<typeof siteThemeSchema>;

export const siteAboutSchema = z.object({
  name: z.string(),
  position: z.string().optional(),
  institution: z.string().optional(),
  degree: z.string().optional(),
  email: z.string().optional(),
  hideEmail: z.boolean().optional(),
});
export type SiteAboutType = z.infer<typeof siteAboutSchema>;

export const siteSocialLinksSchema = z.object({
  twitter: z.string().optional(),
  facebook: z.string().optional(),
  linkedIn: z.string().optional(),
  instagram: z.string().optional(),
  youtube: z.string().optional(),
});
export type SiteSocialLinksType = z.infer<typeof siteSocialLinksSchema>;

export const siteImageCropsSchema = z.object({
  contact: imageCropSchema.nullish(),
  profile: imageCropSchema.nullish(),
});
export type SiteImageCropsType = z.infer<typeof siteImageCropsSchema>;

export const siteWorkItemSchema = z.object({
  id: z.string(),
  title: z.string().optional(),
  titleRichText: richTextSchema.optional(),
  href: z.string(),
});
export type SiteWorkItemType = z.infer<typeof siteWorkItemSchema>;

export const siteWorkSectionSchema = z.object({
  title: z.string().optional(),
  items: z.array(siteWorkItemSchema),
});
export type SiteWorkSectionType = z.infer<typeof siteWorkSectionSchema>;

export const siteEducationItemSchema = z.object({
  id: z.string(),
  title: z.string(),
});
export type SiteEducationItemType = z.infer<typeof siteEducationItemSchema>;

export const siteResumeSchema = z.object({
  name: z.string(),
  uploadID: z.string(),
  sectionTitle: z.string(),
});
export type SiteResumeType = z.infer<typeof siteResumeSchema>;

export const siteBlogPostSchema = z.object({
  id: z.string(),
  slug: z.string(),
  title: z.string(),
  description: z.string(),
  content: richTextSchema,
  dateCreated: z.number().nullish(),
  dateInitiallyPublished: z.number().nullish(),
  dateLastPublished: z.number().nullish(),
});
export type SiteBlogPostType = z.infer<typeof siteBlogPostSchema>;

export const siteBloggingSchema = z.object({
  title: z.string().nullish(),
  enabled: z.boolean(),
  pinnedPostID: z.string().nullish(),
  posts: z.array(siteBlogPostSchema),
});
export type SiteBloggingType = z.infer<typeof siteBloggingSchema>;

export const siteDataSchema = z.object({
  theme: siteThemeSchema,
  retinaID: z.string(),
  imageCrops: siteImageCropsSchema,
  about: siteAboutSchema,
  socialLinks: siteSocialLinksSchema,
  primaryWorks: siteWorkSectionSchema,
  secondaryWorks: siteWorkSectionSchema,
  biography: z.string().optional(),
  biographyRichText: richTextSchema.optional(),
  education: z.array(siteEducationItemSchema),
  blogging: z.any().nullish(),
  irisID: z.string().nullish(),
  resume: siteResumeSchema.nullish(),
});
export type SiteDataType = z.infer<typeof siteDataSchema>;

export const siteSchema = z.object({
  ...baseObjectFields,

  name: z.string(),
  slug: z.string().nullish(),
  domainChoices: z.array(z.string()),
  domain: z.string().nullish(),
  domainIsAutoGenerated: z.boolean().nullish(),
  activeSubscription: z.boolean(),
  completedStages: z.array(completedStageSchema),
  data: siteDataSchema,
  configured: z.boolean(),
  publishedAt: z.number().nullish(),
  deploymentInfo: siteDeploymentInfoSchema.nullish(),
  cropHistory: z.array(cropHistorySchema).optional(),
});
export type SiteType = z.infer<typeof siteSchema>;

export const siteSnapshotSchema = z.object({
  ...baseObjectFields,

  siteData: siteDataSchema,
  version: z.number().default(0),
});
export type SiteSnapshotType = z.infer<typeof siteSnapshotSchema>;
