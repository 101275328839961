import { z } from "zod";
import {
  baseObjectFields,
  richTextSchema,
  socialMediaLinkSchema,
  sortDirectionSchema,
  uploadedImageSchema,
} from "../shared";

export const customFieldKeySchema = z.enum(["A", "B", "C"]);
export type CustomFieldKeyType = z.infer<typeof customFieldKeySchema>;

// DIRECTORY

export const directoryGroupSchema = z.object({
  id: z.string(),

  name: z.string(),
  slug: z.string(),
});

export type DirectoryGroupType = z.infer<typeof directoryGroupSchema>;

export const directoryInfoFieldStructureSchema = z.object({
  fieldOrder: z
    .array(customFieldKeySchema)
    .max(customFieldKeySchema.options.length),

  fieldATitle: z.string().optional(),
  fieldBTitle: z.string().optional(),
  fieldCTitle: z.string().optional(),
});

export type DirectoryInfoFieldStructureType = z.infer<
  typeof directoryInfoFieldStructureSchema
>;

export const directoryLayoutSchema = z.enum(["compact", "large"]);

export type DirectoryLayoutType = z.infer<typeof directoryLayoutSchema>;

export const directoryNavigationLinkSchema = z.object({
  label: z.string(),
  url: z.string(),
});

export type DirectoryNavigationLinkType = z.infer<
  typeof directoryNavigationLinkSchema
>;

export const directoryNavigationSchema = z.object({
  externalLink: directoryNavigationLinkSchema.optional(),
});

export type DirectoryNavigationType = z.infer<typeof directoryNavigationSchema>;

export const directoryVisibilitySchema = z.object({
  isPrivate: z.boolean().optional(),
  password: z.string().optional(),
});

export type DirectoryVisibilityType = z.infer<typeof directoryVisibilitySchema>;

export const directoryContactInfoSchema = z.object({
  email: z.string().optional(),
  showEmailAddress: z.boolean().optional(),
});

export type DirectoryContactInfoType = z.infer<
  typeof directoryContactInfoSchema
>;

export const directoryInfoSchema = z.object({
  title: z.string().optional(),
  tagline: z.string().optional(),
  description: richTextSchema.optional(),
  color: z.string().optional(),

  groupTitle: z.string().optional(),
  groupSortDirection: sortDirectionSchema.optional().default("manual"),
  groups: z.array(directoryGroupSchema),

  navigation: directoryNavigationSchema,

  visibility: directoryVisibilitySchema.optional(),

  fieldStructure: directoryInfoFieldStructureSchema,

  logo: uploadedImageSchema.optional(),
  coverImage: uploadedImageSchema.optional(),

  socialMediaLinks: z.array(socialMediaLinkSchema),

  contactInfo: directoryContactInfoSchema.optional(),

  // layout/design related stuff
  layout: directoryLayoutSchema.optional(),
  showAllGroupsOnHome: z.boolean().optional(),
  hideSearchFiltersOnHome: z.boolean().optional(),

  gaMeasurementId: z.string().nullish(),
});

export type DirectoryInfoType = z.infer<typeof directoryInfoSchema>;

export const directoryImageKindSchema = z.enum(["logo", "coverImage"]);

export type DirectoryImageKindType = z.infer<typeof directoryImageKindSchema>;

export const directoryDeploymentInfoSchema = z.object({
  publishedAt: z.number(),
  currentSnapshotID: z.string(),
});

export type DirectoryDeploymentInfoType = z.infer<
  typeof directoryDeploymentInfoSchema
>;

export const directoryBulkImportUploadedFileSchema = z.object({
  uploadID: z.string(),

  name: z.string(),
  path: z.string(),

  columns: z.array(z.string()).optional(),
});

export type DirectoryBulkImportUploadedFileType = z.infer<
  typeof directoryBulkImportUploadedFileSchema
>;

export const directorySchema = z.object({
  ...baseObjectFields,

  domain: z.string().nullish(),
  domainIsAutoGenerated: z.boolean().nullish(),

  deploymentInfo: directoryDeploymentInfoSchema.nullish(),

  info: directoryInfoSchema,

  bulkImportUploadedFiles: z
    .array(directoryBulkImportUploadedFileSchema)
    .default([]),
});

export type DirectoryType = z.infer<typeof directorySchema>;

export const directorySnapshotMetadataSchema = z.object({
  domain: z.string(),
});

export const directorySnapshotSchema = z.object({
  ...baseObjectFields,

  version: z.number().default(0),

  metadata: directorySnapshotMetadataSchema,

  info: directoryInfoSchema,
});

export type DirectorySnapshotType = z.infer<typeof directorySnapshotSchema>;

export const contentModeSchema = z.enum(["deployment", "live-preview"]);

export type ContentModeType = z.infer<typeof contentModeSchema>;

export const contentMetadataSchema = z.object({
  ...baseObjectFields,

  mode: contentModeSchema,

  deploymentInfo: directoryDeploymentInfoSchema.nullish(),
  domain: z.string().nullish(),
});

export type ContentMetadataType = z.infer<typeof contentMetadataSchema>;

export const contentSchema = z.object({
  metadata: contentMetadataSchema,
});

export const directoryContentSchema = contentSchema.extend({
  info: directoryInfoSchema,
});

export type DirectoryContentType = z.infer<typeof directoryContentSchema>;

// ITEMS

export const directoryPrivateInfoSchema = z.object({
  email: z.string().optional(),
  notes: z.string().optional(),
});

export type DirectoryPrivateInfoType = z.infer<
  typeof directoryPrivateInfoSchema
>;

export const directoryItemSchema = z.object({
  ...baseObjectFields,

  groupID: z.string().optional(),

  slug: z.string().optional(),

  name: z.string(),
  bio: richTextSchema.optional(),

  profileImage: uploadedImageSchema.optional(),

  featured: z.boolean().optional(),

  // custom info (using letters here because they can be re-ordered)
  customFieldA: z.string().optional(),
  customFieldB: z.string().optional(),
  customFieldC: z.string().optional(),

  socialMediaLinks: z.array(socialMediaLinkSchema),

  privateInfo: directoryPrivateInfoSchema.optional(),

  // bulk import related stuff
  originUploadID: z.string().optional(),
  originRow: z.number().optional(),
});

export type DirectoryItemType = z.infer<typeof directoryItemSchema>;

export const algoliaDirectoryItemSchema = directoryItemSchema
  .extend({
    objectID: z.string(),
    directoryID: z.string(),
  })
  .passthrough();

export type AlgoliaDirectoryItemType = z.infer<
  typeof algoliaDirectoryItemSchema
>;

export const directoryCategoryDataItemSchema = z.object({
  name: z.string().optional(),
  value: z.string(),
});

export const directoryCategoryDataSchema = z.object({
  group: z.array(directoryCategoryDataItemSchema),

  // custom info (using letters here because they can be re-ordered)
  customFieldA: z.array(directoryCategoryDataItemSchema),
  customFieldB: z.array(directoryCategoryDataItemSchema),
  customFieldC: z.array(directoryCategoryDataItemSchema),
});

export type DirectoryCategoryDataType = z.infer<
  typeof directoryCategoryDataSchema
>;

// VALIDATION

export const directoryItemPreviewSchema = directoryItemSchema
  .pick({
    slug: true,
    name: true,
    profileImage: true,
    groupID: true,
  })
  .extend({ id: z.string() });

export type DirectoryItemPreviewType = z.infer<
  typeof directoryItemPreviewSchema
>;

export const directoryItemValidationErrorReasonSchema = z.enum([
  "no-slug",
  "invalid-slug",
  "duplicate-slug",
  "no-name",
  "no-group",
  "invalid-group",
]);

export type DirectoryItemValidationErrorReasonType = z.infer<
  typeof directoryItemValidationErrorReasonSchema
>;

export const directoryItemValidationErrorSchema = z.object({
  preview: directoryItemPreviewSchema,
  reason: directoryItemValidationErrorReasonSchema,
});

export type DirectoryItemValidationErrorType = z.infer<
  typeof directoryItemValidationErrorSchema
>;

export const directoryGroupValidationErrorReasonSchema = z.enum([
  "no-slug",
  "invalid-slug",
  "duplicate-slug",
  "no-name",
]);

export type DirectoryGroupValidationErrorReasonType = z.infer<
  typeof directoryGroupValidationErrorReasonSchema
>;

export const directoryGroupValidationErrorSchema = z.object({
  preview: directoryGroupSchema,
  reason: directoryGroupValidationErrorReasonSchema,
});

export type DirectoryGroupValidationErrorType = z.infer<
  typeof directoryGroupValidationErrorSchema
>;

export const directoryValidationErrorReasonSchema = z.enum([
  "no-title",
  "no-group-title",
  "not-subscribed",
  "subscription-expired",
]);

export type DirectoryValidationErrorReasonType = z.infer<
  typeof directoryValidationErrorReasonSchema
>;

export const directoryValidationInvalidResult = z.object({
  valid: z.literal(false),
  itemErrors: z.array(directoryItemValidationErrorSchema),
  groupErrors: z.array(directoryGroupValidationErrorSchema),
  directoryErrors: z.array(directoryValidationErrorReasonSchema),
});

export type DirectoryValidationInvalidResultType = z.infer<
  typeof directoryValidationInvalidResult
>;

export const directoryValidationResultSchema = z.discriminatedUnion("valid", [
  z.object({ valid: z.literal(true) }),
  directoryValidationInvalidResult,
]);

export type DirectoryValidationResultType = z.infer<
  typeof directoryValidationResultSchema
>;
