import { z } from "zod";
import { directoryItemSchema, directorySchema } from "./directory/schema";
import { authUserSchema } from "./docs";
import { type PropertyTypeEnumType, propertyTypeEnumSchema } from "./property";
import { siteSchema } from "./site/schema";

export const userMetadataMembershipKindSchema = z.enum([
  "creator",
  "member",
  "pending",
]);

export type UserMetadataMembershipKindType = z.infer<
  typeof userMetadataMembershipKindSchema
>;

export const userMetadataUserRoleSchema = z.enum(["admin", "user"]);

export const userMetadataSchema = z.object({
  role: userMetadataUserRoleSchema,
  sites: z.record(z.string(), userMetadataMembershipKindSchema),
  directories: z.record(z.string(), userMetadataMembershipKindSchema),
});

export type UserMetadataType = z.infer<typeof userMetadataSchema>;

export const userMetadataHasAccess = (
  type: PropertyTypeEnumType,
  id: string,
  metadata: UserMetadataType,
) => {
  if (type === "site" && metadata.sites[id]) {
    return true;
  } else if (type === "directory" && metadata.directories[id]) {
    return true;
  }

  return false;
};

export const userWithMetadataSchema = z.object({
  user: authUserSchema,
  metadata: userMetadataSchema,
});

export type UserWithMetadataType = z.infer<typeof userWithMetadataSchema>;

export const memberAccessDisplaySchema = z.object({
  userID: z.string().optional(),
  kind: userMetadataMembershipKindSchema,
  name: z.string().optional(), // only present for non-pending where user has a name
  email: z.string(),
  canDelete: z.boolean(),
});

export type MemberAccessDisplayType = z.infer<typeof memberAccessDisplaySchema>;

export const enrichedUserMetadataPropertySchema = z.object({
  id: z.string(),
  title: z.string().nullish(),
  domain: z.string().nullish(),
  kind: userMetadataMembershipKindSchema,
});

export type EnrichedUserMetadataPropertyObject = z.infer<
  typeof enrichedUserMetadataPropertySchema
>;

export const userMetadataResponseSchema = z.object({
  id: z.string(),
  role: userMetadataUserRoleSchema,
  sites: z.array(enrichedUserMetadataPropertySchema),
  directories: z.array(enrichedUserMetadataPropertySchema),
});

export type UserMetadataResponseType = z.infer<
  typeof userMetadataResponseSchema
>;

export const unknownMemberInviteSchema = z.object({
  email: z.string(),

  updatedAt: z.number(),
  createdAt: z.number(),
});

export type UnknownMemberInviteType = z.infer<typeof unknownMemberInviteSchema>;

// TODO: at some point we will need to change this to mobx-state-tree to make it all match

export const bootstrapResponsePropertyItemPreviewSchema = z.object({
  type: propertyTypeEnumSchema,
  id: z.string(),

  title: z.string().nullish(),
  domain: z.string().nullish(),
});

export type BootstrapResponsePropertyItemPreviewType = z.infer<
  typeof bootstrapResponsePropertyItemPreviewSchema
>;

export const bootstrapResponsePropertyItemBaseSchema = z.object({
  key: z.string(),
  members: z.array(memberAccessDisplaySchema),
  version: z.number(),
  membershipKind: userMetadataMembershipKindSchema,
});

export const bootstrapResponseDirectorySchema =
  bootstrapResponsePropertyItemBaseSchema.extend({
    model: directorySchema,
  });

export type BootstrapResponseDirectoryType = z.infer<
  typeof bootstrapResponseDirectorySchema
>;

export const bootstrapResponseSiteSchema =
  bootstrapResponsePropertyItemBaseSchema.extend({
    model: siteSchema,
  });

export type BootstrapResponseSiteType = z.infer<
  typeof bootstrapResponseSiteSchema
>;

export const bootstrapResponseDirectoryItemSchema = z.object({
  model: directoryItemSchema,
  directoryID: z.string(),
  key: z.string(),
  version: z.number(),
});

export type BootstrapResponseDirectoryItemType = z.infer<
  typeof bootstrapResponseDirectoryItemSchema
>;

export const studioBootstrapResponseSchema = z.object({
  user: z.strictObject({
    id: z.string(),
    role: userMetadataUserRoleSchema,
    name: z.string().nullable(),
    email: z.string().nullable(),
    emailVerified: z.boolean(),
  }),
  directories: z.array(bootstrapResponseDirectorySchema),
  directoryItems: z.array(bootstrapResponseDirectoryItemSchema),
  sites: z.array(bootstrapResponseSiteSchema),
  pendingInvites: z.array(bootstrapResponsePropertyItemPreviewSchema),
});

export type StudioBootstrapResponseType = z.infer<
  typeof studioBootstrapResponseSchema
>;
